<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Таблица с проектами"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-icon style="font-size: 32px;">
            mdi-clipboard-text
          </v-icon>
          Таблица с проектами
        </div>
      </template>
      <template v-slot:after-heading>
        <JsonExcel
          :class="`v-btn v-btn--text theme--${$vuetify.theme.dark ? 'dark' : 'light'} v-size--large mt-1`"
          :fetch="fetchData"
          :fields="json_fields"
          type="xls"
          name="проекты.xls"
        >
          <span class="v-btn__content">
            Скачать
            <v-icon>
              mdi-download
            </v-icon>
          </span>
        </JsonExcel>
      </template>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Поиск"
        single-line
        hide-details
      />
      <v-card-title>
        <v-spacer />
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="projects"
        :search="search"
        item-key="project_id"
        show-select
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text':'Проектов на странице'
        }"
        @click:row="clickRow"
      >
        <template v-slot:item.actions="{ item }">
          <div class="mx-7">
            <v-icon
              small
              color="red"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </base-material-card>

    <v-dialog
      v-model="projectModal"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          {{ activeProject.name }}

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="projectModal = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          <v-row>
            <v-col cols="12">
              Дата создания: {{ activeProject.created_at }}
            </v-col>
            <v-col cols="12">
              Регламентная дата: {{ activeProject.collection_time }}
            </v-col>
            <v-col cols="12">
              Количество участников: {{ activeProject.numberOfUsers }}
            </v-col>
            <v-col cols="12">
              Количество согласованных статусов: {{ activeProject.numAgeedStatuses }}
            </v-col>
            <v-col cols="12">
              Количество сводных статусов: {{ activeProject.numberOfStatuses }}
            </v-col>
            <v-col cols="12">
              <p class="mt-2 mb-2">
                Сотрудники
              </p>
              <div
                v-for="(employee, ei) in getEmployees(activeProject)"
                :key="'employee-' + ei"
              >
                <p
                  :class="(userIsActivated(employee) ? 'success' : 'warning') + ' mt-1 mb-1'"
                >
                  <v-icon>
                    mdi-{{ userIsActivated(employee) ? 'account-check' : 'account' }}
                  </v-icon>
                  {{ employee.username }}
                </p>
              </div>
              <p class="mt-2 mb-2">
                Руководитель
              </p>
              <div v-if="activeProject.director !== undefined">
                <div
                  :class="userIsActivated(activeProject.director) ? 'success' : 'warning'"
                >
                  <v-icon>
                    mdi-{{ userIsActivated(activeProject.director) ? 'account-check' : 'account' }}
                  </v-icon>
                  {{ activeProject.director.username }}
                </div>
              </div>
              <p class="mt-2 mb-2">
                Заказчики
              </p>
              <div
                v-for="(client, cli) in getClients(activeProject)"
                :key="'client-' + cli"
              >
                <p
                  :class="userIsActivated(client) ? 'success' : 'warning'"
                >
                  <v-icon>
                    mdi-{{ userIsActivated(client) ? 'account-check' : 'account' }}
                  </v-icon>
                  {{ client.username }}
                </p>
              </div>
              <p class="mt-2 mb-2">
                Администратор проекта
              </p>
              <div v-if="activeProject.admin !== undefined">
                <div
                  :class="userIsActivated(activeProject.admin) ? 'success' : 'warning'"
                >
                  <v-icon>
                    mdi-{{ userIsActivated(activeProject.admin) ? 'account-check' : 'account' }}
                  </v-icon>
                  {{ activeProject.admin.username }}
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-expansion-panels focusable>
                <v-expansion-panel
                  v-for="(week, i) in activeProject.weeks"
                  :key="'week-' + i"
                >
                  <v-expansion-panel-header>Неделя {{ week.seq_num }}</v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-2">
                    <div v-if="getConsolidatedStatus(week.statuses).length === 0">
                      <p>Ответы на вопрос №1</p>
                      <div>
                        <v-textarea
                          :value="getFirstQuestionAnswers(week)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <p>Ответы на вопрос №2</p>
                      <div>
                        <v-textarea
                          :value="getSecondQuestionAnswers(week)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <p>Ответы на вопрос №3</p>
                      <div>
                        <v-textarea
                          :value="getThirdQuestionAnswers(week)"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <div
                        v-for="(status, si) in getConsolidatedStatusOld(week.statuses)"
                        :key="'status-' + si"
                      >
                        <p class="mt-4 mb-4">
                          Согласованный сводный статус
                        </p>
                        <v-textarea
                          :value="status.consolidated_text"
                          :readonly="true"
                          :auto-grow="true"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                    </div>
                    <v-expansion-panels
                      v-else
                      focusable
                    >
                      <v-expansion-panel
                        v-for="(statuses, si) in getConsolidatedStatus(week.statuses, false)"
                        :key="'status-' + si"
                      >
                        <v-expansion-panel-header>Плановый статус</v-expansion-panel-header>
                        <consolidate-status-card
                          :week="week"
                          :statuses="statuses"
                          :active-project="activeProject"
                        />
                      </v-expansion-panel>
                      <v-expansion-panel
                        v-for="(statuses, usi) in getConsolidatedStatus(week.statuses, true)"
                        :key="'unscheduled-status-' + usi"
                      >
                        <v-expansion-panel-header>Внеплановый статус № {{ usi + 1 }}</v-expansion-panel-header>
                        <consolidate-status-card
                          :week="week"
                          :statuses="statuses"
                          :active-project="activeProject"
                        />
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Вы уверены что хотите удалить проект?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
          >
            Да
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="dialogDelete = false"
          >
            Отмена
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import ProjectService from '../../../services/ProjectService'
  import WeekService from '../../../services/WeekService'
  import AnswerService from '../../../services/AnswerService'
  import moment from 'moment-timezone'
  import ConsolidateStatusService from '../../../services/ConsolidateStatusService'

  export default {
    components: {
      ConsolidateStatusCard: () => import('../../../components/ConsolidateStatusCard'),
    },

    data () {
      return {
        search: '',
        selected: [],
        headers: [
          { text: 'Название', value: 'name', class: 'bold--text display-1' },
          { text: 'Дата создания', value: 'created_at', class: 'bold--text display-1' },
          { text: 'Регламентная дата', value: 'collection_time', class: 'bold--text display-1' },
          { text: 'Количество участников', value: 'numberOfUsers', class: 'bold--text display-1' },
          { text: 'Количество сводных статусов', value: 'numberOfStatuses', class: 'bold--text display-1' },
          { text: 'Количество согласованных статусов', value: 'numAgeedStatuses', class: 'bold--text display-1' },
          { text: 'Действия', value: 'actions', class: 'bold--text display-1 mx-auto', sortable: false },
        ],
        projects: [],
        json_fields: {
          Название: 'name',
          'Дата создания': 'created_at',
          'Регламентная дата': 'collection_time',
          'Количество участников': 'numberOfUsers',
          'Количество сводных статусов': 'numberOfStatuses',
          'Количество согласованных статусов': 'numAgeedStatuses',
        },
        projectModal: false,
        activeProject: {
          id: '',
          name: '',
          created_at: '',
          collection_time: '',
          numberOfUsers: '',
          numberOfStatuses: '',
          numAgeedStatuses: '',
          users: [],
          weeks: [
            {
              seq_num: 1,
              statuses: [
                {
                  answers: [
                    {
                      user_id: '',
                      answers_text: [
                        {
                          type: '',
                          text: '',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          index: 0,
        },
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          _id: '',
        },
      }
    },

    async mounted () {
      const self = this
      const projectsData = await ProjectService.getAll()
      self.projects = []

      for await (const projectData of projectsData) {
        const project = await {
          project_id: projectData._id,
          name: projectData.name,
          created_at: moment(projectData.createdAt).tz('Europe/Moscow').format('DD.MM.YYYY HH:mm [по мск.]'),
          collection_time: ProjectService.getCollectionTimeString(projectData.collection_start_time, projectData.collection_start_day),
          numberOfUsers: projectData.numberOfUsers,
          numberOfStatuses: projectData.numberOfStatuses,
          users: projectData.users,
        }
        project.weeks = await WeekService.getAll(null, project.project_id)
        project.numAgeedStatuses = self.getNumAgeedStatuses(project)
        self.projects.push(project)
      }
    },

    methods: {
      async fetchData () {
        const self = this
        const selectedProjects = []

        for await (const selected of self.selected) {
          selectedProjects.push(selected)
        }

        if (selectedProjects.length === 0) {
          self.$snackbar.showMessage({ content: 'Выберите хотя бы 1 проект', color: 'warning' })
          return
        }

        return selectedProjects
      },
      async updateActiveProject (project) {
        this.activeProject.id = project.project_id
        this.activeProject.name = project.name
        this.activeProject.created_at = project.created_at
        this.activeProject.collection_time = project.collection_time
        this.activeProject.numberOfUsers = project.numberOfUsers
        this.activeProject.numberOfStatuses = project.numberOfStatuses
        this.activeProject.users = project.users
        this.activeProject.director = this.getDirector(project)
        this.activeProject.admin = this.getAdmin(project)
        this.activeProject.weeks = project.weeks.filter((week) => week.status !== WeekService.STATUS_SKIP)
        this.activeProject.numAgeedStatuses = project.numAgeedStatuses
      },
      async clickRow (item, event) {
        if (this.dialogDelete) {
          return
        }

        const activeProject = item

        if (activeProject) {
          await this.updateActiveProject(activeProject)
        }

        this.projectModal = true
      },
      getAnswers (status, users) {
        let answerText = ''

        for (const answer of status.answers) {
          const user = users.find((user) => user.user_id === answer.user_id)
          if (user) {
            answerText += AnswerService.getUserAnswer(answer, user)
          }
        }

        return answerText
      },
      getFirstQuestionAnswers (week, users) {
        const answers = AnswerService.getAnswers(week, AnswerService.TYPE_COMPLETED, null)
        return answers.join('\n')
      },
      getSecondQuestionAnswers (week, users) {
        const answers = AnswerService.getAnswers(week, AnswerService.STATUS_PROGRESS, null)
        return answers.join('\n')
      },
      getThirdQuestionAnswers (week, users) {
        const answers = AnswerService.getAnswers(week, AnswerService.STATUS_FINISHED, null)
        return answers.join('\n')
      },
      getConsolidatedStatus (statuses, isUnscheduled) {
        const consolidatedStatuses = []
        const agreedStatuses = statuses.filter((status) => status.type === ConsolidateStatusService.TYPE_AGREED)

        agreedStatuses.forEach(agreedStatus => {
          const formedParentStatus = statuses.filter((status) => status._id === agreedStatus.parent_id && status.type === ConsolidateStatusService.TYPE_FORMED)

          if (formedParentStatus.length === 0) {
            return
          }

          if (isUnscheduled !== undefined) {
            if (agreedStatus.isUnscheduled !== isUnscheduled) {
              return
            }
          }

          const editedStatuses = statuses.filter((status) => status.parent_id === agreedStatus._id)

          consolidatedStatuses.push({
            formed: formedParentStatus.pop(),
            agreed: agreedStatus,
            edited: editedStatuses,
          })
        })

        return consolidatedStatuses
      },
      getConsolidatedStatusOld (statuses) {
        const consolidatedStatuses = statuses.filter((status) => status.type === ConsolidateStatusService.TYPE_AGREED)
        return consolidatedStatuses
      },
      deleteItem (item) {
        this.editedIndex = this.projects.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        const vm = this
        const project = vm.projects[this.editedIndex]
        ProjectService.delete(project.project_id).then(response => {
          vm.projects.splice(this.editedIndex, 1)
          vm.dialogDelete = false
          vm.$snackbar.showMessage({ content: 'Проект удален', color: 'success' })
        }).catch(error => {
          let errMessage = 'Что то пошло не так'
          if (error.response && error.response.data.message) {
            errMessage = error.response.data.message
          }
          vm.$snackbar.showMessage({ content: errMessage, color: 'error' })
        })
      },
      getNumAgeedStatuses (project) {
        return ProjectService.getNumAgeedStatuses(project)
      },
      getEmployees (project) {
        return ProjectService.getEmployees(project)
      },
      getDirector (project) {
        return ProjectService.getDirector(project)
      },
      getAdmin (project) {
        return ProjectService.getAdmin(project)
      },
      getClients (project) {
        return ProjectService.getClients(project)
      },
      userIsActivated (user) {
        if (!user || user === undefined) {
          return false
        }

        return parseInt(user.status) === ProjectService.USER_STATUS_ACTIVATED
      },
    },
  }
</script>

<style>
  .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: inherit;
  }
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: inherit;
  }
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    margin: 0;
  }
</style>
